window.addEventListener("hashchange", function (event) {
  var hash = window.location.hash;
  var params = new URLSearchParams(hash.replace("#", "?"));

  if (params.has("copy")) {
    var copy = params.get("copy");
    var message = params.get("message") || "Code wurde in die Zwischenablage kopiert!";

    navigator.clipboard.writeText(copy).then(
      function () {
        CeresNotification.success(message).closeAfter(3000);
      },
      function () {
        CeresNotification.error("Leider konnte der Code nicht in die Zwischenablage kopiert werden.").closeAfter(3000);
      }
    );
  }
});
