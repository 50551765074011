const urlParams = new URLSearchParams(window.location.search);
const klaviyoForm = urlParams.get('klaviyo_form');

if (klaviyoForm) {
    const observer = new MutationObserver(function (records) {
        const $form = $(document.body).find(`form[data-testid="klaviyo-form-${klaviyoForm}"]`);
        if (!$form.length) return;

        observer.disconnect();

        const params = Array.from(urlParams.entries()).filter(([key]) => key.startsWith("klaviyo_")).reduce(
            (acc, [key, value]) => ({ ...acc, [key.replace("klaviyo_", "")]: value }),
            {}
        );
        const delay = params.delay ? parseInt(params.delay) : 1000;

        setTimeout(function() {
            for (const parameters of Object.entries(params)) {
                const [key, value] = parameters;
                const $element = $form.find(`input[id^="${key.replace("klaviyo_", "")}"]`);
                const element = $element.get(0);

                if (element) {
                    $element.click().prop("checked", value === "true").val(value);
                    $element.get(0).dispatchEvent(new Event("blur"));
                }
            }
        }, delay);        
    });

    $(document).ready(function () {
        observer.observe(document.body, { childList: true, subtree: true });
        window._klOnsite = window._klOnsite || [];
        window._klOnsite.push(['openForm', klaviyoForm]);
    });
}
