const modalContent = `<div class="modal-wrapper" id="back-in-stock-modal">
    <div class="modal-overlay"></div>
    <form class="modal-content">
        <i class="modal-close">×</i>
        <h3>Dein Liebling ist bald wieder da. Wir erinnern dich per Mail! ⏰</h3>
        <input type="text" id="back-in-stock-variation" name="variation" value="" style="display: none;">
        <input type="email" name="email" placeholder="Deine E-Mail-Adresse" required>
        <div class="modal-error" aria-hidden style="display: none;"></div>
        <button class="btn btn-primary" type="submit">Kaufalarm aktivieren</button>
    </form>
</div>`;

window.subscribeToBackInStockClick = (e) => {
  e.preventDefault();
  var variationId = $(e.currentTarget).data("variation-id");

  if ($("#back-in-stock-modal").length < 1) {
    // Append modal to body only once
    $("body").append($(modalContent));

    // Back in Stock notification
    $("#back-in-stock-modal .modal-close, #back-in-stock-modal .modal-overlay").click(function () {
      $(document.body).removeClass("back-in-stock-visible");
    });

    $("#back-in-stock-modal form").submit(function (e) {
      e.preventDefault();

      var data = $(this)
        .serializeArray()
        .reduce(function (obj, item) {
          obj[item.name] = item.value;
          return obj;
        }, {});

      if (!data.email || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(data.email)) {
        $("#back-in-stock-modal .modal-error").text("Bitte geben Sie eine gültige E-Mail-Adresse ein.").show();
        return false;
      }

      ceresStore.dispatch("addToWishList", parseInt(data.variation));
      $("#back-in-stock-modal button").text("Wird aktiviert...").prop("disabled", true);
      $.ajax({
        type: "POST",
        url: "https://a.klaviyo.com/onsite/components/back-in-stock/subscribe",
        data: {
          a: "Yxcfgn",
          email: data.email,
          variant: data.variation,
          platform: "api",
        },
        success: () => {
          CeresNotification.success("Du hast Dich für den Kaufalarm angemeldet!").closeAfter(3000);
          $(document.body).removeClass("back-in-stock-visible");
          $("#back-in-stock-modal button").text("Kaufalarm aktivieren").prop("disabled", false);
          $("#back-in-stock-modal .modal-error").text("").hide();
        },
        error: () => {
          CeresNotification.error(
            "Leider konnten wir Dich nicht für den Kaufalarm anmelden. Versuche es später noch einmal."
          ).closeAfter(3000);
          $(document.body).removeClass("back-in-stock-visible");
          $("#back-in-stock-modal button").text("Kaufalarm aktivieren").prop("disabled", false);
          $("#back-in-stock-modal .modal-error").text("").hide();
        },
      });

      return false;
    });
  }

  $("#back-in-stock-variation").val(variationId);
  $(document.body).addClass("back-in-stock-visible");
};
